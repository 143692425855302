
@media (max-width: 575.98px) {
     .topTitle{ font-size: 30px; } 
     .topSubTitle{ font-size: 16px;  }
     .projectCard{ width: 26em; margin-top: 50px; }
     .aboutMeImage{  width: 400px; height: 400px;  }
 }


@media (min-width: 576px) and (max-width: 767.98px) { 
     .topTitle{ font-size: 38px; }
     .topSubTitle{ font-size: 19px;  }
 }


@media (min-width: 768px) and (max-width: 991.98px) { 
     
 }


@media (min-width: 992px) and (max-width: 1199.98px) { 
     
 }


@media (min-width: 1200px) { 
     
 }